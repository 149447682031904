import React from 'react';
import { Container } from 'react-bootstrap';
import Faq from './faq';
import Accordion from 'react-bootstrap/Accordion';

export default class Faqs extends React.Component {
  constructor(props) {
    super(props);
    this.faqs = require('data/faqs');
  }

  render() {
    const selectedFaq = this.props.location.hash.replace('#', '');

    return (
      <Container id="faqs">
        <h2>Frequently Asked Questions</h2>
        <Accordion defaultActiveKey={selectedFaq}>
          {this.faqs.map(faq => {
            return (
              <Faq
                key={faq.id}
                scrollTo={selectedFaq === faq.id}
                eventKey={faq.id}
                question={faq.question}
                answer={faq.answer}
              />
            );
          })}
        </Accordion>
      </Container>
    );
  }
}
