import {MapContainer, WMSTileLayer, GeoJSON} from "react-leaflet";
import './sites-map.scss';
import SetMarkerCluster from "./set-marker-cluster";

export default function SitesMap(props) {
  const defaultMapConfig = {
    zoom: 5,
    maxZoom: 13
  };

  let mapInputProps = {
    zoom: defaultMapConfig.zoom,
    scrollWheelZoom: false,
    maxZoom: defaultMapConfig.maxZoom,
    preferCanvas: true
  };
  
  return (
    <div className="map">
      <MapContainer {...mapInputProps}>
        <WMSTileLayer
          url="https://maps.eatlas.org.au/maps/gwc/service/wms"
          layers="ea-be:World_Bright-Earth-e-Atlas-basemap"
          tiled={true}
          srs="EPSG:3857"
        />
        <GeoJSON
          key="GBR1_boundaries"
          data={props.boundaries.gbr1}
          style={() => ({
            fillColor: '#47e7dd',
            fillOpacity: 0.15,
            stroke: true,
            color: '#00a277',
            opacity: 1,
            weight: 1
          })}
        />
        <GeoJSON
          key="GBR4_boundaries"
          data={props.boundaries.gbr4}
          style={() => ({
            fillColor: '#c48a39',
            fillOpacity: 0.15,
            stroke: true,
            color: '#c27e00',
            opacity: 1,
            weight: 1
          })}
        />
        <SetMarkerCluster
          sites={props.sites}
          isActive={props.isActive}
          maxZoom={defaultMapConfig.maxZoom}
        />
      </MapContainer>
      <p className="small">
        The model boundaries are shown on the map. The GBR 1k model boundaries
        are shown in green, the GBR 4k model boundaries are shown in orange.
      </p>
    </div>
  );
}
