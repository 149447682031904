import React from 'react';
import { Alert } from 'react-bootstrap';
import {differenceInHours, differenceInDays, differenceInMonths} from 'date-fns';

export default function DownloadFileSize(props) {
  const { selectedData } = props;

  let timeSteps;
  switch (selectedData.timeStep) {
    case 'hourly':
      // We need to add 24h to include the first day
      timeSteps = differenceInHours(selectedData.dateRange.to, selectedData.dateRange.from) + 24;
      break;
    case 'daily':
      timeSteps = differenceInDays(selectedData.dateRange.to, selectedData.dateRange.from);
      break;
    case 'monthly':
      timeSteps = differenceInMonths(selectedData.dateRange.to, selectedData.dateRange.from) + 1;
      break;
    default:
      timeSteps = 0;
  }
  
  const numberOfDepths = Object.keys(selectedData.depths).filter(
      key => selectedData.depths[key] === true
    ).length;

  const rows =
    selectedData.sites.length *
    selectedData.variables.length *
    ( numberOfDepths > 0 ? numberOfDepths : 1 ) *
    timeSteps;

  const approxFileSizeBytes =
    rows * selectedData.dataCollection.approxFileRowSize;

  let fileSizeString;
  if (approxFileSizeBytes > 1073741824) {
    fileSizeString =
      (approxFileSizeBytes / 1024 / 1024 / 1042).toFixed(1) + 'GB';
  } else if (approxFileSizeBytes > 1048576) {
    fileSizeString = (approxFileSizeBytes / 1024 / 1024).toFixed(1) + 'MB';
  } else if (approxFileSizeBytes > 1024) {
    fileSizeString = (approxFileSizeBytes / 1024).toFixed(0) + 'KB';
  } else {
    fileSizeString = approxFileSizeBytes + 'B';
  }

  // show warning when file size > 200MB
  let alert;
  if (approxFileSizeBytes > 209715200) {
    alert = (
      <Alert variant="warning">
        Please consider breaking up the data extraction into multiple smaller
        extractions because the resulting file could be hard to process.
        <br />
        Download file size: approx. {fileSizeString}
      </Alert>
    );
  } else {
    alert = (
      <Alert variant="info">Download file size: approx. {fileSizeString}</Alert>
    );
  }

  return alert;
}
