import React from 'react';
import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';
import './extraction-tool-nav-bar.scss';

export default function ExtractionToolNavBar(props) {
  const { location } = props;

  return (
    <Navbar variant="light" bg="white" expand="lg" id="extraction-tool-nav-bar">
      <div className="container">
        <Navbar.Brand href="/">Data Extraction Tool</Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="mr-auto" activeKey={location.pathname}>
            <Nav.Link href="/">Overview</Nav.Link>
            <Nav.Link href="/extract-data">Extract Data</Nav.Link>
            <Nav.Link href="/faqs">FAQs</Nav.Link>
          </Nav>
        </Navbar.Collapse>
      </div>
    </Navbar>
  );
}
