import React from 'react';
import './page-header.scss';

/**
 * @return {null}
 */
export default function PageHeader(props) {
  const { location } = props;
  if (location.pathname !== '/') {
    return <section className="page-header" />;
  } else {
    return null;
  }
}
