import React from 'react';
import './notification.scss';

export default class Notification extends React.Component {
  render() {
    switch (this.props.status) {
      case 'success':
        return (
          <div className="notification text-success">{this.props.message}</div>
        );
      case 'warning':
        return (
          <div className="notification text-warning">{this.props.message}</div>
        );
      case 'error':
        return (
          <div className="notification text-danger">{this.props.message}</div>
        );
      default:
        return null;
    }
  }
}
