import React from 'react';
import { Modal, Button } from 'react-bootstrap';

export default function ErrorMessage(props) {
  return (
    <Modal
      show={props.show}
      onHide={props.onHide}
      size="lg"
      aria-labelledby="contained-modal-title"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title">
          <span className="text-danger">{props.title}</span>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>{props.message}</p>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="primary" onClick={props.onHide}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
