import React from 'react';
import { renderToString } from 'react-dom/server';
import {
  Container,
  Row,
  Col,
  Button,
  Card,
  Form,
  Alert,
} from 'react-bootstrap';

import './step-select-variables.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';

export default class StepSelectVariables extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      selectionInfoText: null,
    };
  }

  selectDataCollection = (dataCollectionId) => {
    const dataCollection = this.props.dataCollections.find(
      (dataCollection) => dataCollection.id === dataCollectionId
    );

    this.props.updateSelectedData('variables', []);
    this.props.updateSelectedData('dataCollection', dataCollection);
    this.setState({ selectionInfoText: dataCollection.selectionInfo });
  };

  selectVariable = (variableId) => {
    let selectedVariables = this.props.selectedData.variables;
    const variable = this.props.selectedData.dataCollection.variables.find(
      (variable) => variable.id === variableId
    );
    const index = selectedVariables.indexOf(variable);
    if (index === -1) {
      // add variable
      selectedVariables.push(variable);
    } else {
      // remove variable
      selectedVariables.splice(index, 1);
    }

    this.props.updateSelectedData('variables', selectedVariables);
  };

  render() {
    return (
      <div className="step-select-variables">
        <h2 className="text-center">Select Variables</h2>
        <p className="sub-header">
          Select the data collection variables to extract the data from. Only
          variables from one data collection can be selected.
        </p>

        <Container className="form margin-bottom">
          <Form.Group>
            <Form.Label column={false}>Data collection:</Form.Label>
            {this.props.selectedData.dataCollection !== null &&
              this.props.showResetInfo && (
                <p className="text-info small">
                  <FontAwesomeIcon className="info-icon" icon={faInfoCircle} />
                  Changing the selected data collection will reset depth and
                  time selections to prevent invalid values.
                </p>
              )}
            {this.state.selectionInfoText && (
              <Alert variant="warning">
                <p
                  dangerouslySetInnerHTML={{
                    __html: this.state.selectionInfoText,
                  }}
                />
              </Alert>
            )}
            <Row>
              {this.props.dataCollections.map((dataCollection) => {
                const collectionSelected =
                  this.props.selectedData.dataCollection &&
                  this.props.selectedData.dataCollection.id ===
                    dataCollection.id;
                let cardProps = {};
                if (collectionSelected) {
                  cardProps.border = 'primary';
                }

                return (
                  <Col
                    className="mb-4"
                    lg="4"
                    md="12"
                    key={'col-' + dataCollection.id}
                  >
                    <Card
                      key={dataCollection.id}
                      className="mb-3"
                      {...cardProps}
                    >
                      <Card.Header>
                        <Card.Title>{dataCollection.name}</Card.Title>
                        <Button
                          title={collectionSelected ? dataCollection.name + " selected" : "Select " + dataCollection.name}
                          variant="primary"
                          disabled={collectionSelected}
                          onClick={() =>
                            this.selectDataCollection(dataCollection.id)
                          }
                        >
                          <Form.Check
                            inline
                            type="radio"
                            checked={collectionSelected || false}
                            readOnly={true}
                          />
                          {collectionSelected ? 'Selected' : 'Select'}
                        </Button>
                      </Card.Header>
                      <Card.Body>
                        <Card.Text>Version: {dataCollection.version}</Card.Text>
                        <Card.Text>{dataCollection.description}</Card.Text>
                        <Card.Text>More information:</Card.Text>
                        <ul>
                          <li>
                            <a
                              href={dataCollection.modelOutputs}
                              rel="noopener noreferrer"
                              target="_blank"
                            >
                              Model Outputs
                            </a>
                          </li>
                          {dataCollection.skillAssessment ? (
                            <li>
                              <a
                                href={dataCollection.skillAssessment}
                                rel="noopener noreferrer"
                                target="_blank"
                              >
                                Skill Assessment
                              </a>
                            </li>
                          ) : (
                            ''
                          )}
                        </ul>
                      </Card.Body>
                    </Card>
                  </Col>
                );
              })}
            </Row>
          </Form.Group>

          <Form.Group>
            <Form.Label column={false}>
              Variables
              {this.props.selectedData.dataCollection !== null
                ? ' for ' + this.props.selectedData.dataCollection.name
                : ''}
              :
            </Form.Label>
            {this.props.selectedData.dataCollection !== null &&
              this.props.selectedData.dataCollection.variablesInfo && (
                <p
                  className="text-info small"
                  dangerouslySetInnerHTML={{
                    __html:
                      renderToString(
                        <FontAwesomeIcon
                          className="info-icon"
                          icon={faInfoCircle}
                        />
                      ) + this.props.selectedData.dataCollection.variablesInfo,
                  }}
                />
              )}
            {this.props.selectedData.dataCollection === null ? (
              <p>Please select data collection first.</p>
            ) : (
              this.props.selectedData.dataCollection.variables.map(
                (variable, index) => {
                  let variableSelected = false;
                  if (this.props.selectedData.variables.map(variable => variable.id).includes(variable.id)) {
                    variableSelected = true;
                  }
                  
                  let inputProps = {};
                  if (this.props.selectedData.dataCollection.variables.length === index + 1) {
                    inputProps.className = 'last-item';
                  }

                  let visualisationLink = '';
                  if (variable.visualisationLink !== '') {
                    visualisationLink = (
                      <>
                        {' '}
                        <a
                          href={variable.visualisationLink}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          visualisation
                        </a>
                      </>
                    );
                  }

                  return (
                    <Form.Check
                      key={
                        'formCheck-' +
                        this.props.selectedData.dataCollection.id +
                        '-' +
                        variable.id
                      }
                      type="checkbox"
                      id={
                        this.props.selectedData.dataCollection.id +
                        '-' +
                        variable.id
                      }
                    >
                      <Form.Check.Input
                        type="checkbox"
                        onChange={() => this.selectVariable(variable.id)}
                        checked={variableSelected || false}
                      />
                      <Form.Check.Label>
                        {variable.name} ({variable.id})
                      </Form.Check.Label>
                      {visualisationLink}
                    </Form.Check>
                  );
                }
              )
            )}
          </Form.Group>
        </Container>
        <Container>
          <Row>
            <Col className="align-self-end text-start first">
              <Button variant="primary" onClick={this.props.previousStep}>
                Previous Step
              </Button>
            </Col>
            <Col className="align-self-end text-end last">
              <Button
                variant="primary"
                onClick={this.props.nextStep}
                disabled={
                  !this.props.finishedSteps.selectVariables ||
                  !this.props.finishedSteps.selectSites
                }
              >
                Next Step
              </Button>
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
}
