import React from 'react';
import { Alert, Container } from 'react-bootstrap';
import { differenceInHours, differenceInDays, differenceInMonths } from 'date-fns';

export default function JobSize(props) {
  const { sites, variables, dateRange, timeStep, depths } = props.selectedData;

  const depthsValues = Object.keys(depths).filter(
    (key) => depths[key] === true
  );

  let times = 0;
  switch (timeStep) {
    case 'hourly':
      // We need to add 24h to include the first day
      times = differenceInHours(dateRange.to, dateRange.from) + 24;
      break;
    case 'daily':
      times = differenceInDays(dateRange.to, dateRange.from);
      break;
    case 'monthly':
      // add 1 month because differenceInMonths only calculates the month between the dates
      times = differenceInMonths(dateRange.to, dateRange.from) + 1;
      break;
    default:
      break;
  }

  const dataPoints =
    sites.length *
    variables.length *
    (depthsValues.length > 0 ? depthsValues.length : 1) *
    times;

  // show warning when data points exceed threshold
  const thresholds = props.selectedData.dataCollection.sizeThresholds;
  let alert;
  if (dataPoints < thresholds.small) {
    alert = (
      <Alert variant="info">
        You have selected {dataPoints.toLocaleString()} data points (sites,
        variables, {depthsValues.length > 0 ? 'depths, ' : ''}time steps). This
        extraction request can take up to 30 minutes
        <sup>*</sup>.
      </Alert>
    );
  } else if (dataPoints < thresholds.medium) {
    alert = (
      <Alert variant="info">
        You have selected {dataPoints.toLocaleString()} data points (sites,
        variables, {depthsValues.length > 0 ? 'depths, ' : ''}time steps). This
        extraction request can take up to 2 hours<sup>*</sup>.
      </Alert>
    );
  } else if (dataPoints > thresholds.medium) {
    alert = (
      <Alert variant="warning">
        You have selected {dataPoints.toLocaleString()} data points (sites,
        variables, {depthsValues.length > 0 ? 'depths, ' : ''}time steps). This
        extraction request can take more than 2 hours
        <sup>*</sup>. Please consider reducing the amount of data points to
        avoid long processing times.
      </Alert>
    );
  }

  return (
    <Container>
      {alert}
      <p className="text-info small">
        <sup>*</sup> This is a rough estimate. The actual processing time can
        vary and depends on multiple conditions (e.g. number of parallel
        requests).
      </p>
    </Container>
  );
}
