import React from 'react';
import { Container } from 'react-bootstrap';

export default class GenericNotFound extends React.Component {
  render() {
    return (
      <Container>
        <h2>Page Not Found</h2>
        <p>Sorry, but the page you were trying to view does not exist.</p>
      </Container>
    );
  }
}
