import React from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import { withRouter } from 'react-router-dom';
import AppHeader from 'components/app-header';
import AppNavBar from 'components/app-nav-bar';
import ExtractionToolNavBar from 'components/extraction-tool-nav-bar';
import PageHeader from 'components/page-header';
import Main from 'components/main';
import Footer from 'components/footer';
import usePageTracking from '../../utilities/hooks/usePageTracking';

function App() {
  const AppNavBarWithRouter = withRouter(AppNavBar);
  const ExtractionToolNavBarWithRouter = withRouter(ExtractionToolNavBar);
  const PageHeaderWithRouter = withRouter(PageHeader);
  const MainWithRouter = withRouter(Main);

  // wrap app content to use page tracking hook
  const AppContent = () => {
    usePageTracking();

    return (
      <>
        <AppHeader />
        <AppNavBarWithRouter />
        <ExtractionToolNavBarWithRouter />
        <PageHeaderWithRouter />
        <MainWithRouter />
        <Footer />
      </>
    );
  };

  return (
    <div className="app">
      <Router>
        <AppContent />
      </Router>
    </div>
  );
}

export default App;
