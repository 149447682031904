import React from 'react';
import { Col, Form, Row } from 'react-bootstrap';

export default class Depth extends React.Component {
  handleDepthChange = event => {
    const { selectedDepths } = this.props;
    selectedDepths[event.target.value] = event.target.checked;

    this.props.onDepthChange(selectedDepths);
  };

  render() {
    const { selectedDepths } = this.props;

    let formData;
    if (this.props.depthsAvailableForVariables) {
      // split depths into columns if length > 20
      let columns;
      if (this.props.dataCollectionDepths.length > 20) {
        const numberOfColumns = 4;
        const maxItems = Math.ceil(
          this.props.dataCollectionDepths.length / numberOfColumns
        );
        columns = Array(numberOfColumns)
          .fill()
          .map((_, i) => {
            return this.props.dataCollectionDepths.slice(
              i * maxItems,
              i * maxItems + maxItems
            );
          });
      } else {
        // wrap depths items into a column array
        columns = [this.props.dataCollectionDepths];
      }

      formData = columns.map((column, colIndex) => {
        const orderSmClass =
          'order-sm-' + (colIndex % 2 === 0 ? colIndex : colIndex + 6);
        const orderMdClass = 'order-md-' + colIndex;
        return (
          <Col
            sm={6}
            md={3}
            className={orderSmClass + ' ' + orderMdClass}
            key={'select-depths-col-' + colIndex}
          >
            {column.map((depth, index) => (
              <Form.Check
                key={'depth-' + colIndex + '-' + index}
                type="checkbox"
                id={'depth-' + colIndex + '-' + index}
                value={depth}
                name="depth"
                label={depth + ' m'}
                onChange={this.handleDepthChange}
                checked={selectedDepths[depth]}
              />
            ))}
          </Col>
        );
      });
    } else {
      formData = (
        <Col>
          <p>No depths available for selected variables.</p>
        </Col>
      );
    }

    return (
      <div className="depth">
        <Form.Group>
          <Form.Label column={false}>Select depth:</Form.Label>
          <Row>{formData}</Row>
        </Form.Group>
      </div>
    );
  }
}
