import {useEffect} from 'react';

import {
  CONNECTION_SPEED_FAST, 
  CONNECTION_SPEED_MEDIUM, 
  CONNECTION_SPEED_SLOW
} from "./connection-speeds";

/**
 * @return {string}
 */
export default function DetectConnectionSpeed(props) {
  const fileSize = 20463;
  const thresholdFastKbps = 500;
  const thresholdMediumKbps = 300;

  useEffect(() => {
    const startTime = Date.now();
    fetch('/speed-test.jpg?n=' + Math.random(), {
      mode: 'no-cors',
      cache: 'no-cache'
    })
      .then(() => {
        const duration = Math.round(Date.now() - startTime) / 1000;
        const bitsLoaded = fileSize * 8;
        const speedKbps = (Math.round(bitsLoaded / duration) / 1024).toFixed(2);

        // set isSlowConnection to true when speedMbps is smaller than 1Mbps
        if (speedKbps > thresholdFastKbps) {
          props.updateConnectionSpeed(CONNECTION_SPEED_FAST);
        } else if (speedKbps > thresholdMediumKbps) {
          props.updateConnectionSpeed(CONNECTION_SPEED_MEDIUM);
        } else {
          console.log('slow connection detected: ', speedKbps);
          props.updateConnectionSpeed(CONNECTION_SPEED_SLOW);
        }
      })
      .catch(() => props.updateConnectionSpeed(CONNECTION_SPEED_SLOW));
  });

  return '';
}
