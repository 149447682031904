import React from 'react';
import Nav from 'react-bootstrap/Nav';
import './step-nav.scss';

export default function StepNav(props) {
  return (
    <Nav variant="pills" justify={true} className="step-nav margin-bottom">
      <Nav.Item>
        <Nav.Link
          key={`select-sites`}
          className={`step-nav-item ${props.currentStep === 1 ? 'active' : ''}`}
          onClick={() => props.goToStep(1)}
        >
          <h5>1. Sites</h5>
          <p>Select the sites</p>
        </Nav.Link>
      </Nav.Item>
      <Nav.Item>
        <Nav.Link
          key={`select-variables`}
          className={`step-nav-item ${props.currentStep === 2 ? 'active' : ''}`}
          onClick={() => props.goToStep(2)}
        >
          <h5>2. Variables</h5>
          <p>Select the data collection and variables</p>
        </Nav.Link>
      </Nav.Item>
      <Nav.Item>
        <Nav.Link
          key={`select-depth-and-time`}
          className={`step-nav-item ${props.currentStep === 3 ? 'active' : ''}`}
          onClick={() => props.goToStep(3)}
          disabled={
            !props.finishedSteps.selectSites ||
            !props.finishedSteps.selectVariables
          }
        >
          <h5>3. Depth and Time</h5>
          <p>Select the depth and time range</p>
        </Nav.Link>
      </Nav.Item>
      <Nav.Item>
        <Nav.Link
          key={`submit`}
          className={`step-nav-item ${props.currentStep === 4 ? 'active' : ''}`}
          onClick={() => props.goToStep(4)}
          disabled={
            !props.finishedSteps.selectSites ||
            !props.finishedSteps.selectVariables ||
            !props.finishedSteps.selectDepthAndTime
          }
        >
          <h5>4. Submit</h5>
          <p>Summary of selection and submit request</p>
        </Nav.Link>
      </Nav.Item>
    </Nav>
  );
}
