import React from 'react';
import { Form } from 'react-bootstrap';
import api from 'utilities/api';
import Spinner from 'react-bootstrap/Spinner';
import Stepper from './stepper';
import {parseISO, format} from 'date-fns';

export default class Status extends React.Component {
  constructor(props) {
    super(props);

    this.stepsSuccess = [
      {
        indicator: '1',
        title: 'Submitted',
        description: 'The extraction request has been submitted.'
      },
      {
        indicator: '2',
        title: 'Queued',
        description:
          'The extraction request has been placed on the queue to be processed. The queue time depends on the number of other extraction jobs being processed. Typical queue times are 5 min – 8 hours.'
      },
      {
        indicator: '3',
        title: 'Running',
        description:
          'The extraction request is being processed. Extraction times are typically 10 min – 8 hours.'
      },
      {
        indicator: '4',
        title: 'Completed',
        description:
          'The extraction of the data is completed. The data is now ready for download.'
      }
    ];
    this.stepsFailure = [
      {
        indicator: '1',
        title: 'Submitted',
        description:
          'The extraction request has been submitted and is waiting for approval.'
      },
      {
        indicator: '2',
        title: 'Failed',
        description:
          'An error occurred while processing the request. Please contact the system administrator for further information.'
      }
    ];

    this.state = {
      loading: true,
      requestDataStatus: null,
      activeStep: 1,
      isFailed: false,
      startedProcessingAt: null,
      finishedProcessingAt: null
    };
  }

  updateStatus = data => {
    const status = data.status;

    let activeStep;
    let isFailed = false;
    switch (status) {
      case 'SUBMITTED':
        activeStep = 1;
        break;
      case 'QUEUED':
        activeStep = 2;
        break;
      case 'RUNNING':
        activeStep = 3;
        break;
      case 'COMPLETED':
        activeStep = 4;
        if (data.extras && data.extras.fileIds) {
          const downloadUrls = data.extras.fileIds.map(
            fileId =>
              process.env.REACT_APP_API_URL +
              'request/' +
              this.props.requestId +
              '/files/' +
              fileId
          );
          this.props.setDownloadUrls(downloadUrls);
        }

        break;
      default:
        // failed
        activeStep = 2;
        isFailed = true;
    }

    this.setState({
      loading: false,
      requestDataStatus: status,
      activeStep: activeStep,
      isFailed: isFailed,
      startedProcessingAt: data.extras.startedProcessingAt,
      finishedProcessingAt: data.extras.finishedProcessingAt
    });
  };

  componentDidMount = async () => {
    await api
      .get('request/' + this.props.requestId + '/status')
      .then(response => {
        this.updateStatus(response.data);
      })
      .catch(() => {
        this.setState({
          loading: false
        });
      });
  };

  render() {
    return (
      <>
        <h3>Status</h3>
        <Form.Group>
          <Form.Label column={false}>Extraction Request Status:</Form.Label>
          {this.state.loading === true ? (
            <div className="text-center">
              <Spinner animation="border" role="status" variant="primary" />
              <br />
              <p>Loading current status...</p>
            </div>
          ) : this.state.requestDataStatus !== null ? (
            <>
              <Stepper
                steps={
                  this.state.isFailed ? this.stepsFailure : this.stepsSuccess
                }
                activeStep={this.state.activeStep}
                isFailed={this.state.isFailed}
              />
              {this.state.startedProcessingAt ? (
                <p>
                  Processing started at:{' '}
                  {format(parseISO(this.state.startedProcessingAt), 'd MMMM yyyy hh:mm a')}
                  <br />
                  {this.state.finishedProcessingAt ? (
                    <>
                      Processing finished at:{' '}
                      {format(parseISO(this.state.finishedProcessingAt), 'd MMMM yyyy hh:mm a')}
                    </>
                  ) : (
                    ''
                  )}
                </p>
              ) : (
                ''
              )}
            </>
          ) : (
            <p className="text-center text-danger">
              Error while loading the status. Please try reloading the page or
              contact the system administrator.
            </p>
          )}
        </Form.Group>
      </>
    );
  }
}
