import React from 'react';
import { Container, Row, Col, Button } from 'react-bootstrap';

import DateRange from './date-range';
import TimeStep from './time-step';
import Depth from './depth';

export default class StepSelectDepthAndTime extends React.Component {
  handleDateRangeChange = dateRange => {
    this.props.updateSelectedData('dateRange', dateRange);
  };

  handleTimeStepChange = timeStep => {
    this.props.updateSelectedData('timeStep', timeStep);
  };

  handleDepthChange = depths => {
    this.props.updateSelectedData('depths', depths);
  };

  render() {
    if (this.props.finishedSteps.selectSites !== true) {
      return (
        <React.Fragment>
          <p className="text-center">Please select sites first.</p>
          <p className="text-center">
            <Button variant="primary" onClick={() => this.props.goToStep(1)}>
              Select Sites
            </Button>
          </p>
        </React.Fragment>
      );
    } else if (this.props.finishedSteps.selectVariables !== true) {
      return (
        <React.Fragment>
          <p className="text-center">Please select variables first.</p>
          <p className="text-center">
            <Button variant="primary" onClick={() => this.props.goToStep(2)}>
              Select Variables
            </Button>
          </p>
        </React.Fragment>
      );
    }

    const {
      name,
      startDate,
      endDate,
      timeZone,
      depths,
      timeSteps,
      timeStepsInfo
    } = this.props.selectedData.dataCollection;
    return (
      <div className="step-select-depth-and-time">
        <h2 className="text-center">Select Depth and Time</h2>
        <p className="sub-header">
          Select a time range with time steps and the depth for which to extract
          the data.
        </p>
        <Container className="form margin-bottom">
          <DateRange
            minDate={startDate}
            maxDate={endDate}
            timeZone={timeZone}
            selectedDateRangeFrom={
              this.props.selectedData.dateRange
                ? this.props.selectedData.dateRange.from
                : null
            }
            selectedDateRangeTo={
              this.props.selectedData.dateRange
                ? this.props.selectedData.dateRange.to
                : null
            }
            dataCollectionName={name}
            onDateRangeChange={this.handleDateRangeChange}
          />
          <TimeStep
            dataCollectionTimeSteps={timeSteps}
            dataCollectionTimeStepsInfo={timeStepsInfo}
            onTimeStepChange={this.handleTimeStepChange}
            selectedTimeStep={this.props.selectedData.timeStep}
          />
          <Depth
            depthsAvailableForVariables={this.props.depthsAvailableForVariables}
            dataCollectionDepths={depths}
            selectedDepths={this.props.selectedData.depths}
            onDepthChange={this.handleDepthChange}
          />
        </Container>
        <Container>
          <Row>
            <Col className="align-self-end text-start first">
              <Button variant="primary" onClick={this.props.previousStep}>
                Previous Step
              </Button>
            </Col>
            <Col className="align-self-end text-end last">
              <Button
                variant="primary"
                onClick={this.props.nextStep}
                disabled={!this.props.finishedSteps.selectDepthAndTime}
              >
                Next Step
              </Button>
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
}
