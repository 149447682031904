import React from 'react';
import {createRoot} from "react-dom/client";
import './index.scss';
import 'core-js/stable';
import 'regenerator-runtime/runtime';
import 'whatwg-fetch';
import App from './components/app';

const root = createRoot(document.getElementById('root'));
root.render(<App/>);
