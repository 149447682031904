import React from 'react';
import { Container, Row, Col, Button, Form } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import api from 'utilities/api';

import './step-submit.scss';
import ErrorMessage from './error-message';
import SelectedDataOverview from '../selected-data-overview';
import DownloadFileSize from './download-file-size';
import JobSize from './job-size';

import {format} from 'date-fns';

const datasetDateFormat = 'yyyy-MM-dd';

export default class StepSubmit extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      stepFinished: false,
      email: '',
      emailValidationMessage: '',
      emailTouched: false,
      privacyPolicy: false,
      licenceAgreement: false,
      name: '',
      description: '',
      isSending: false,
      submitError: false,
      submitErrorMessage: '',
    };
    this.nameMaxLength = 120;
    this.descriptionMaxLength = 500;
  }

  updateStepFinished() {
    const {
      email,
      emailValidationMessage,
      privacyPolicy,
      licenceAgreement,
      name,
      description,
    } = this.state;
    const stepFinished =
      email !== '' &&
      emailValidationMessage === '' &&
      privacyPolicy === true &&
      licenceAgreement === true &&
      (name === '' || name.length <= this.nameMaxLength) &&
      (description === '' || description.length <= this.descriptionMaxLength);
    this.setState({ stepFinished: stepFinished });
  }

  handleEmailInput = (event) => {
    const value = event.target.value;
    const validationMessage = this.state.emailTouched
      ? event.target.validationMessage
      : '';
    this.setState(
      { email: value, emailValidationMessage: validationMessage },
      this.updateStepFinished
    );
  };

  onEmailBlur = (event) => {
    event.persist();
    this.setState({ emailTouched: true }, () => {
      this.handleEmailInput(event);
    });
  };

  handleCheckboxInput = (event) => {
    const name = event.target.name;
    const value = event.target.checked;
    this.setState({ [name]: value }, this.updateStepFinished);
  };

  handleNameInput = (event) => {
    this.setState({ name: event.target.value });
  };

  handleDescriptionInput = (event) => {
    this.setState({ description: event.target.value });
  };

  handleSubmit = async (event) => {
    event.preventDefault();

    this.setState({ isSending: true });

    await api
      .post(
        'request',
        {
          sites: this.props.selectedData.sites,
          dataCollection: this.props.selectedData.dataCollection.id,
          dataCollectionVersion: this.props.selectedData.dataCollection.version,
          variables: this.props.selectedData.variables.map(
            (variable) => variable.id
          ),
          dateRangeFrom: format(this.props.selectedData.dateRange.from, datasetDateFormat),
          dateRangeTo: format(this.props.selectedData.dateRange.to, datasetDateFormat),
          timeStep: this.props.selectedData.timeStep,
          depths: Object.keys(this.props.selectedData.depths)
            .filter((key) => this.props.selectedData.depths[key] === true)
            .map((key) => key),
          name: this.state.name,
          description: this.state.description,
          userData: {
            email: this.state.email,
            privacyPolicyAccepted: this.state.privacyPolicy,
            licenceAgreementAccepted: this.state.licenceAgreement,
          },
        },
        {
          headers: { 'Content-Type': 'application/json' },
        }
      )
      .then((response) => {
        this.props.resetSelectedData();
        this.props.history.push('/data/' + response.data.id);
      })
      .catch((error) => {
        let errorMessage;
        if (error.response) {
          // The request was made and the server responded with a status code
          // that falls out of the range of 2xx
          errorMessage = error.response.data ? (
            error.response.data
          ) : (
            <p>
              An error occurred while processing the request. Please contact the
              system administrator (
              <a
                href={'mailto:' + process.env.REACT_APP_CONTACT_EMAIL_ADDRESS}
                target="_blank"
                rel="noopener noreferrer"
              >
                {process.env.REACT_APP_CONTACT_EMAIL_ADDRESS}
              </a>
              )
            </p>
          );
        } else if (error.request) {
          // The request was made but no response was received
          errorMessage = (
            <p>
              Unable to send form. Please try again or contact the system
              administrator (
              <a
                href={'mailto:' + process.env.REACT_APP_CONTACT_EMAIL_ADDRESS}
                target="_blank"
                rel="noopener noreferrer"
              >
                {process.env.REACT_APP_CONTACT_EMAIL_ADDRESS}
              </a>
              )
            </p>
          );
        } else {
          console.log(error);
          // Something happened in setting up the request that triggered an Error
          errorMessage = (
            <p>
              An error occurred. Please contact the system administrator (
              <a
                href={'mailto:' + process.env.REACT_APP_CONTACT_EMAIL_ADDRESS}
                target="_blank"
                rel="noopener noreferrer"
              >
                {process.env.REACT_APP_CONTACT_EMAIL_ADDRESS}
              </a>
              )
            </p>
          );
        }
        this.setState({
          submitError: true,
          submitErrorMessage: errorMessage,
          isSending: false,
        });
      });

    return false;
  };

  render() {
    if (!this.props.isActive) {
      return null;
    }

    if (this.props.finishedSteps.selectSites !== true) {
      return (
        <React.Fragment>
          <p className="text-center">Please select sites first.</p>
          <p className="text-center">
            <Button variant="primary" onClick={() => this.props.goToStep(1)}>
              Select Sites
            </Button>
          </p>
        </React.Fragment>
      );
    } else if (this.props.finishedSteps.selectVariables !== true) {
      return (
        <React.Fragment>
          <p className="text-center">Please select variables first.</p>
          <p className="text-center">
            <Button variant="primary" onClick={() => this.props.goToStep(2)}>
              Select Variables
            </Button>
          </p>
        </React.Fragment>
      );
    } else if (this.props.finishedSteps.selectDepthAndTime !== true) {
      return (
        <React.Fragment>
          <p className="text-center">Please select depth and time first.</p>
          <p className="text-center">
            <Button variant="primary" onClick={() => this.props.goToStep(3)}>
              Select Depth and Time
            </Button>
          </p>
        </React.Fragment>
      );
    }

    return (
      <div className="step-submit">
        <ErrorMessage
          show={this.state.submitError}
          onHide={() =>
            this.setState({ submitError: false, submitErrorMessage: '' })
          }
          title="Submit request failed"
          message={this.state.submitErrorMessage}
        />
        <h2 className="text-center">Submit Data-Extraction Request</h2>
        <p className="sub-header">Review and submit data extraction request.</p>
        <JobSize selectedData={this.props.selectedData} />
        <Container className="form margin-bottom">
          <SelectedDataOverview
            headline="Review selected data"
            selectedData={this.props.selectedData}
          />
          <DownloadFileSize selectedData={this.props.selectedData} />
        </Container>
        <Container className="form margin-bottom">
          <h3>Confirmation</h3>
          <Form>
            <Form.Group className="form-group" controlId="email">
              <Form.Label column={false}>Email address:</Form.Label>
              <Form.Control
                type="email"
                name="email"
                placeholder="Enter email"
                value={this.state.email}
                onChange={this.handleEmailInput}
                onBlur={this.onEmailBlur}
                required
                className={
                  this.state.emailValidationMessage !== '' ? 'is-invalid' : ''
                }
              />
              <Form.Control.Feedback type="invalid">
                {this.state.emailValidationMessage}
              </Form.Control.Feedback>
              <Form.Text className="text-muted">
                Extracting data can take a while. We will inform you via email
                when your data is ready for download.
              </Form.Text>
            </Form.Group>

            <Form.Group className="form-group" controlId="privacyPolicy">
              <Form.Label column={false}>Privacy Policy:</Form.Label>
              <Form.Check
                type="checkbox"
                name="privacyPolicy"
                checked={this.state.privacyPolicy}
                label={
                  <span>
                    I accept the{' '}
                    <Link to="/privacy-policy" target="_blank">
                      Privacy Policy
                    </Link>
                  </span>
                }
                onChange={this.handleCheckboxInput}
              />
            </Form.Group>

            <Form.Group className="form-group" controlId="licence">
              <Form.Label column={false}>Licence agreement:</Form.Label>
              <Form.Check
                type="checkbox"
                name="licenceAgreement"
                checked={this.state.licenceAgreement}
                label={
                  <span>
                    I accept the licence agreement:
                    <br />
                  </span>
                }
                onChange={this.handleCheckboxInput}
              />
              <div
                className="licence"
                dangerouslySetInnerHTML={{
                  __html: this.props.selectedData.dataCollection.licence,
                }}
              />
            </Form.Group>

            <Form.Group className="form-group" controlId="name">
              <Form.Label column={false}>Title (optional):</Form.Label>
              <Form.Control
                type="text"
                name="name"
                onChange={this.handleNameInput}
                rows="3"
                placeholder="Title"
                value={this.state.name}
                maxLength={this.nameMaxLength}
              />
              <Form.Text className="text-muted">
                <span className="character-counter">
                  {this.state.name.length}/{this.nameMaxLength}
                </span>
                Maximum length: {this.nameMaxLength} characters
                <br />
                Please provide a title for the extracted data for future reference. Please be aware
                that this information will be displayed on the extraction data page which can be publicly 
                accessible.
              </Form.Text>
            </Form.Group>

            <Form.Group className="form-group" controlId="description">
              <Form.Label column={false}>Description (optional):</Form.Label>
              <Form.Control
                as="textarea"
                name="description"
                onChange={this.handleDescriptionInput}
                rows="3"
                placeholder="Description"
                value={this.state.description}
                maxLength={this.descriptionMaxLength}
              />
              <Form.Text className="text-muted">
                <span className="character-counter">
                  {this.state.description.length}/{this.descriptionMaxLength}
                </span>
                Maximum length: {this.descriptionMaxLength} characters
                <br />
                Please provide a short description of the intended purpose of
                the extracted data. Please be aware that this information will
                be displayed on the extraction data page which can be publicly
                accessible.
              </Form.Text>
            </Form.Group>
          </Form>
        </Container>
        <Container className="form margin-bottom d-flex justify-content-end">
          <Form.Group className="form-group">
            <Button
              variant="primary"
              type="submit"
              onClick={this.handleSubmit}
              disabled={!this.state.stepFinished || this.state.isSending}
            >
              {this.state.isSending ? 'Sending…' : 'Submit'}
            </Button>
          </Form.Group>
        </Container>

        <Container>
          <Row>
            <Col className="align-self-end text-start first">
              <Button variant="primary" onClick={this.props.previousStep}>
                Previous Step
              </Button>
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
}
