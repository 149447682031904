import React from 'react';
import Container from "react-bootstrap/Container";
import Button from "react-bootstrap/Button";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Figure from "react-bootstrap/Figure";
import {
  CONNECTION_SPEED_FAST, 
  CONNECTION_SPEED_MEDIUM
} from 'utilities/detect-connection-speed/connection-speeds'; 
import DetectConnectionSpeed from 'utilities/detect-connection-speed';

import './home.scss';

import HeroVideo from 'assets/videos/ereefs-hero-keppels-480p-2mbps.mp4';
import EReefsOverviewVideo from 'assets/videos/eReefs-overview-800px-800kbps.mp4';
import ExtractionOverviewImg from 'assets/images/extraction-overview.svg';
import EReefsOverviewSnapshotImg from 'assets/images/eReefs-overview-snapshot.png';
import GridComparisonMapImg1x from 'assets/images/grid-comparison-map-1x.jpg';
import GridComparisonMapImg2x from 'assets/images/grid-comparison-map-2x.jpg';
import CoralsImg from 'assets/images/corals.jpg';
import GBRFLogoImg from 'assets/images/GBRF-logo.png';
import BoMLogoImg from 'assets/images/BoM-logo.png';
import CSIROLogoImg from 'assets/images/CSIRO-logo.png';
import AIMSLogoImg from 'assets/images/AIMS-logo.png';
import QLDGovLogoImg from 'assets/images/QLD-Gov-logo.png';

export default class Home extends React.Component {
  constructor() {
    super();

    this.state = {
      connectionSpeedCategory: null
    };
  }

  updateConnectionSpeed = speedCategory => {
    this.setState({
      connectionSpeedCategory: speedCategory
    });
  };

  render() {
    return (
      <Container id="home">
        <section id="hero">
          {this.state.connectionSpeedCategory === null ? (
            <DetectConnectionSpeed
              updateConnectionSpeed={this.updateConnectionSpeed}
            />
          ) : (
            ''
          )}
          <div className="overlay" />
          <div className="image" />
          <Container>
            <h1>eReefs Data Extraction Tool</h1>
            <p className="sub-header">
              Download environment conditions at your research sites on the
              Great Barrier Reef.
            </p>
            <p className="text-center button">
              <Button variant="success" size="lg" href="/extract-data">
                Get started
              </Button>
            </p>
          </Container>
          {/*only show video when connection is not slow*/}
          {this.state.connectionSpeedCategory === CONNECTION_SPEED_FAST ? (
            <video autoPlay={true} loop={true}>
              <source src={HeroVideo} type="video/mp4" />
            </video>
          ) : (
            ''
          )}
        </section>
        <section id="extraction-tool-description">
          <Container>
            <Row className="align-items-center">
              <Col md={5} className="order-md-1">
                <h2>Time Series Extraction</h2>
                <p>
                  This tool allows you to extract time series data for the
                  environmental variables and locations of interest from the
                  eReefs models.
                </p>
              </Col>
              <Col md={7} className="order-md-5">
                <img
                  src={ExtractionOverviewImg}
                  alt="Extraction overview graph"
                />
              </Col>
            </Row>
          </Container>
        </section>
        <section id="eReefs-description">
          <Container>
            <Row className="align-items-center">
              <Col xl={4} lg={6} className="order-lg-5">
                <h2>eReefs Models</h2>
                <p>
                  This tool provides access to the eReefs environmental models.
                  These models capture the 3D dynamics of the waters of the
                  Great Barrier Reef (GBR). This suite is broken into modelling
                  the movement of the water (hydrodynamics), and constituents of
                  the water (biogeochemicals). These models provide an estimate
                  of common environmental variables (temperature, salinity,
                  current, light, turbidity, nutrients) at all locations on the
                  GBR over a 9 year time span.
                </p>
              </Col>
              <Col xl={8} lg={6} className="order-lg-1">
                <Figure>
                  {this.state.connectionSpeedCategory ===
                    CONNECTION_SPEED_FAST ||
                  this.state.connectionSpeedCategory ===
                    CONNECTION_SPEED_MEDIUM ? (
                    <video autoPlay={false} loop={false} controls={true}>
                      <source src={EReefsOverviewVideo} type="video/mp4" />
                    </video>
                  ) : (
                    <img
                      src={EReefsOverviewSnapshotImg}
                      alt="Extraction overview graph"
                    />
                  )}

                  <figcaption className="figure-caption">
                    Example{' '}
                    <a
                      href="https://aims.ereefs.org.au"
                      target="_blank"
                      rel="noreferrer noopener"
                    >
                      visualisation
                    </a>{' '}
                    of the eReefs 4 km hydrodynamic model.
                  </figcaption>
                </Figure>
              </Col>
            </Row>
          </Container>
        </section>
        <section id="model-definitions">
          <Container>
            <Row className="align-items-center">
              <Col xl={4} lg={6} className="order-lg-1">
                <h2>Available Model Data</h2>
                <p>
                  Each model is available over a different time span and sample
                  rate. The hydrodynamics is modelled at 1 km and 4 km
                  resolution and is available as hourly data. The biogeochemical
                  model is available at 4 km resolution and has daily (midday
                  sample) data available.
                </p>
                <p>Hydrodynamic:</p>
                <ul>
                  <li>1km December 2014 - hourly</li>
                  <li>4km September 2010 - hourly</li>
                </ul>
                <p>Biogeochemical:</p>
                <ul>
                  <li>4km Jan 2015 - daily</li>
                </ul>
              </Col>
              <Col xl={8} lg={6} className="order-lg-5">
                <img
                  srcSet={`${GridComparisonMapImg1x} 1x, ${GridComparisonMapImg2x} 2x`}
                  src={GridComparisonMapImg1x}
                  alt="Extraction overview graph"
                />
              </Col>
            </Row>
          </Container>
        </section>
        <section id="collaborators">
          <Container>
            <Row className="justify-content-center">
              <Col md={12}>
                <h2>eReefs Collaborators</h2>
              </Col>
            </Row>
            <Row className="collaborator-logos justify-content-around align-items-center">
              <Col>
                <a
                  href="https://www.barrierreef.org/"
                  title="Great Barrier Reef Foundation"
                  target="_blank"
                  rel="noreferrer noopener"
                >
                  <img src={GBRFLogoImg} alt="GBRF logo" />
                </a>
              </Col>
              <Col>
                <a
                  href="http://www.bom.gov.au/"
                  title="Bureau Of Meteorology"
                  target="_blank"
                  rel="noreferrer noopener"
                >
                  <img src={BoMLogoImg} alt="BoM logo" />
                </a>
              </Col>
              <Col>
                <a
                  href="https://www.csiro.au/"
                  title="Commonwealth Scientific and Industrial Research Organisation"
                  target="_blank"
                  rel="noreferrer noopener"
                >
                  <img src={CSIROLogoImg} alt="CSIRO logo" />
                </a>
              </Col>
              <Col>
                <a
                  href="https://www.aims.gov.au/"
                  title="Australian Institute of Marine Science"
                  target="_blank"
                  rel="noreferrer noopener"
                >
                  <img src={AIMSLogoImg} alt="AIMS logo" />
                </a>
              </Col>
              <Col>
                <a
                  href="https://www.qld.gov.au/"
                  title="Queensland Government"
                  target="_blank"
                  rel="noreferrer noopener"
                >
                  <img src={QLDGovLogoImg} alt="QLD Government logo" />
                </a>
              </Col>
            </Row>
            <Row>
              <Col md={12}>
                <p>
                  The eReefs research project is a collaboration between the
                  Great Barrier Reef Foundation, Bureau of Meteorology, CSIRO,
                  the Australian Institute of Marine Science, and Queensland
                  Government.
                </p>
              </Col>
            </Row>
          </Container>
        </section>
        <section id="other-data">
          <Container>
            <Row className="justify-content-center">
              <Col md={12}>
                <Row className="align-items-center box">
                  <Col md={5} className="order-md-1">
                    <h2>Other eReefs data</h2>
                    <ul>
                      <li>
                        <a
                          href="https://ereefs.aims.gov.au"
                          title="AIMS eReefs visualisation portal"
                          target="_blank"
                          rel="noreferrer noopener"
                        >
                          AIMS eReefs Visualisations
                        </a>
                      </li>
                      <li>
                        <a
                          href="https://research.csiro.au/ereefs/models/model-outputs/access-to-raw-model-output/"
                          title="Access to raw model output"
                          target="_blank"
                          rel="noreferrer noopener"
                        >
                          Raw model data
                        </a>
                      </li>
                      <li>
                        <a
                          href="https://thredds.ereefs.aims.gov.au/thredds/catalog.html"
                          title="Access to aggregation data"
                          target="_blank"
                          rel="noreferrer noopener"
                        >
                          Aggregation Data
                        </a>
                      </li>
                    </ul>
                  </Col>
                  <Col md={7} className="order-md-12">
                    <img src={CoralsImg} alt="Corals" />
                  </Col>
                </Row>
              </Col>
            </Row>
          </Container>
        </section>
      </Container>
    );
  }
}
