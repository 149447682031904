import React from 'react';
import './app-header.scss';

import { Container } from 'react-bootstrap';

import AimsLogo from 'assets/images/AIMS-logo.png';
import AimsLogoSml from 'assets/images/AIMS-logo-sml.png';

/**
 * @return {null}
 */
export default function AppHeader() {
  return (
    <header className="app-header">
      <Container>
        <a
          className="aims-logo"
          href="https://www.aims.gov.au"
          title="Australian Institute of Marine Science"
        >
          <img
            src={AimsLogo}
            alt="Australian Institute of Marine Science logo"
            aria-label="aims-logo"
          />
        </a>
        <div className="text-nowrap title">
          <img
            className="aims-logo-sml"
            src={AimsLogoSml}
            alt="AIMS small logo"
            aria-label="aims-small-logo"
          />
          AIMS eReefs
        </div>
      </Container>
    </header>
  );
}
