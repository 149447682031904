import React from 'react';
import { Container } from 'react-bootstrap';
import api from 'utilities/api';
import Spinner from 'react-bootstrap/Spinner';
import SelectedDataOverview from 'components/main/extract-data/selected-data-overview';
import Status from './status';
import DownloadLinks from './downloadLinks';

import './data.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import {format, parseISO, parse} from "date-fns";

const datasetDateFormat = 'yyyy-MM-dd';

export default class Data extends React.Component {
  constructor(props) {
    super(props);
    this.dataCollections = require('data/data-collections');

    this.state = {
      loading: true,
      requestData: null,
      downloadUrls: null,
    };
  }

  updateDownloadUrls = (downloadUrls) => {
    this.setState({
      downloadUrls: downloadUrls,
    });
  };

  async componentDidMount(): void {
    await api
      .get('request/' + this.props.match.params.id)
      .then((response) => {
        const requestData = response.data;
        // replace data collection id with actual data collection object
        requestData.dataCollection = this.dataCollections.find(
          (collection) => collection.id === requestData.dataCollection
        );

        // replace variable IDs with variable objects
        requestData.variables = requestData.variables.map((variableId) =>
          requestData.dataCollection.variables.find(
            (variable) => variable.id === variableId
          )
        );

        // combine single date range values to object and create date object
        requestData.dateRange = {
          from: parse(requestData.dateRangeFrom, datasetDateFormat, new Date()),
          to: parse(requestData.dateRangeTo, datasetDateFormat, new Date()),
        };
        delete requestData.dateRangeFrom;
        delete requestData.dateRangeTo;

        // assign all depths from data collection and set to true when in depths array
        requestData.depths = requestData.dataCollection.depths.reduce(
          (hashMap, depth) => {
            hashMap[depth] = requestData.depths.includes(depth);
            return hashMap;
          },
          {}
        );

        this.setState({
          loading: false,
          requestData: requestData,
        });
      })
      .catch(() => {
        this.setState({
          loading: false,
        });
      });
  }

  render() {
    return (
      <Container id="data">
        <span className="header-info">
          Extraction request ID: {this.props.match.params.id}
          <br />
          Submitted at:{' '}
          {this.state.requestData
            ? format(parseISO(this.state.requestData.submittedAt), 'd MMMM yyyy hh:mm a')
            : ''}
        </span>
        <h2 className="text-center">
          Extraction request
          {this.state.requestData !== null &&
          this.state.requestData.name !== '' ? (
            <>{': ' + this.state.requestData.name}</>
          ) : (
            ''
          )}
        </h2>
        <p className="sub-header">
          See the status of the extraction request and download the data once it
          is completed.
        </p>
        <Container className="margin-bottom">
          <p className="text-info">
            <FontAwesomeIcon className="info-icon" icon={faInfoCircle} />
            To see the current status please refresh this page. Please bare in
            mind that the extraction process can take up to 8 hours (in some
            cases even longer, depending on the selected data). You can safely
            close this window and come back at any time. A link to this page has
            been sent to you in the confirmation email. We will inform you via
            email when the extraction process is finished and the data is ready
            for download.
          </p>
        </Container>
        {this.state.loading === true ? (
          <div className="text-center">
            <Spinner animation="border" role="status" variant="primary" />
            <br />
            <p>Loading request data...</p>
          </div>
        ) : this.state.requestData !== null ? (
          <>
            <Container className="form margin-bottom">
              <Status
                requestId={this.props.match.params.id}
                setDownloadUrls={this.updateDownloadUrls}
              />
            </Container>
            <Container className="form margin-bottom">
              <DownloadLinks
                downloadUrls={this.state.downloadUrls}
                dataCollection={this.state.requestData.dataCollection}
              />
            </Container>
            <Container className="form margin-bottom">
              <h3>Description</h3>
              {this.state.requestData.description !== '' ? (
                <p>{this.state.requestData.description}</p>
              ) : (
                <p>No description provided.</p>
              )}
            </Container>
            <Container className="form margin-bottom">
              <SelectedDataOverview
                headline="Parameters"
                selectedData={this.state.requestData}
              />
            </Container>
          </>
        ) : (
          <p className="text-center text-danger">
            Error while loading request data. Please check the extraction
            request ID or contact the system administrator.
          </p>
        )}
      </Container>
    );
  }
}
