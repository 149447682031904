import React from 'react';
import StepWizard from 'react-step-wizard';
import StepNav from './step-nav';
import StepSelectSites from './step-select-sites';
import StepSelectDepthAndTime from './step-select-depth-and-time';
import StepSelectVariables from './step-select-variables';
import StepSubmit from './step-submit';
import './extract-data.scss';
import Container from 'react-bootstrap/Container';

export default class ExtractData extends React.Component {
  constructor(props) {
    super(props);
    this.beforeUnloadListener = null;
    this.dataCollections = require('data/data-collections');

    this.state = {
      selectedData: {
        sites: [],
        dataCollection: null,
        variables: [],
        dateRange: null,
        timeStep: null,
        depths: null,
      },
      showResetInfo: false,
      finishedSteps: {
        selectSites: false,
        selectVariables: false,
        selectDepthAndTime: false,
      },
      // no animated transitions
      transitions: {
        enterRight: `show-step`,
        enterLeft: `show-step`,
        exitRight: `hide-step`,
        exitLeft: `hide-step`,
        intro: ``,
      },
    };
  }

  componentDidMount() {
    this.beforeUnloadListener = (e) => {
      // no data has been selected yet
      if (
        this.state.selectedData.sites.length === 0 &&
        this.state.selectedData.dataCollection === null
      ) {
        return undefined;
      }

      let confirmationMessage =
        'If you leave the page before submitting, all your selections will be lost. Are you sure you want to leave?';

      (e || window.event).returnValue = confirmationMessage; //Gecko + IE
      return confirmationMessage; //Gecko + Webkit, Safari, Chrome etc.
    };
    window.addEventListener('beforeunload', this.beforeUnloadListener);
  }

  componentWillUnmount(): void {
    window.removeEventListener('beforeunload', this.beforeUnloadListener);
  }

  resetSelectedData = () => {
    this.setState({
      selectedData: {
        sites: [],
        dataCollection: null,
        variables: [],
        dateRange: null,
        timeStep: null,
        depths: null,
      },
      finishedSteps: {
        selectSites: false,
        selectVariables: false,
        selectDepthAndTime: false,
      },
    });
  };

  updateSelectedData = (key, value) => {
    let { selectedData, showResetInfo } = this.state;

    // Once time and depths were selected always show reset info
    if (key === 'dateRange' || key === 'timeStep' || key === 'depths') {
      showResetInfo = true;
    }

    // reset depths and time when data collection changes
    if (key === 'dataCollection') {
      selectedData['dateRange'] = null;
      selectedData['timeStep'] = null;
      selectedData['depths'] = value.depths.reduce((hashMap, depth) => {
        hashMap[depth] = false;
        return hashMap;
      }, {});
    }
    // reset depths when only variables without depths are selected
    else if (
      this.state.selectedData.variables.length > 0 &&
      !this.isDepthRequired()
    ) {
      selectedData[
        'depths'
      ] = this.state.selectedData.dataCollection.depths.reduce(
        (hashMap, depth) => {
          hashMap[depth] = false;
          return hashMap;
        },
        {}
      );
    }

    selectedData[key] = value;
    this.setState({ selectedData, showResetInfo }, this.updateFinishedSteps);
  };

  updateFinishedSteps = () => {
    const { finishedSteps } = this.state;

    finishedSteps['selectSites'] = this.isSelectSitesStepFinished();
    finishedSteps['selectVariables'] = this.isSelectVariablesStepFinished();
    finishedSteps['selectDepthAndTime'] = this.isSelectDepthAndTimeStepFinished();
    this.setState({ finishedSteps });
  };

  isSelectSitesStepFinished() {
    return this.state.selectedData.sites.length > 0;
  }

  isSelectVariablesStepFinished() {
    const { dataCollection, variables } = this.state.selectedData;
    return dataCollection && variables.length > 0;
  }

  isSelectDepthAndTimeStepFinished() {
    const { dateRange, timeStep, depths } = this.state.selectedData;
    let stepFinished =
      dateRange !== null &&
      dateRange.from !== null &&
      dateRange.to !== null &&
      timeStep !== null;

    if (this.isDepthRequired()) {
      stepFinished =
        stepFinished &&
        depths !== null &&
        Object.keys(depths).filter((key) => depths[key] === true).length > 0;
    }

    return stepFinished;
  }

  isDepthRequired = () => {
    return (
      this.state.selectedData.variables.filter(
        (variable) => variable.depths === true
      ).length > 0
    );
  };

  render() {
    return (
      <Container id="extract-data">
        <StepWizard
          isHashEnabled
          transitions={this.state.transitions} // comment out this line to use default transitions
          nav={<StepNav finishedSteps={this.state.finishedSteps} />}
          instance={this.setInstance}
        >
          <StepSelectSites
            hashKey={'select-sites'}
            updateSelectedData={this.updateSelectedData}
            selectedData={this.state.selectedData}
            isLazyMount={true}
          />
          <StepSelectVariables
            hashKey={'select-variables'}
            updateSelectedData={this.updateSelectedData}
            dataCollections={this.dataCollections}
            selectedData={this.state.selectedData}
            showResetInfo={this.state.showResetInfo}
            finishedSteps={this.state.finishedSteps}
            isLazyMount={true}
          />
          <StepSelectDepthAndTime
            hashKey={'select-depth-and-time'}
            updateSelectedData={this.updateSelectedData}
            selectedData={this.state.selectedData}
            depthsAvailableForVariables={this.isDepthRequired()}
            finishedSteps={this.state.finishedSteps}
            isLazyMount={true}
          />
          <StepSubmit
            hashKey={'submit'}
            finishedSteps={this.state.finishedSteps}
            selectedData={this.state.selectedData}
            resetSelectedData={this.resetSelectedData}
            history={this.props.history}
            isLazyMount={true}
          />
        </StepWizard>
      </Container>
    );
  }
}
