import React from 'react';
import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';
import './app-nav-bar.scss';

export default function AppNavBar() {
  return (
    <Navbar expand="lg" id="app-nav-bar">
      <div className="container">
        <Navbar.Toggle aria-controls="app-navbar-nav" />
        <Navbar.Collapse id="app-navbar-nav">
          <Nav className="mr-auto">
            <Nav.Link className="first" href="https://ereefs.aims.gov.au">
              Visualisation Portal
            </Nav.Link>
            <Nav.Link className="active" href="/">
              Data Extraction
            </Nav.Link>
            <Nav.Link href="https://ereefs.aims.gov.au/ereefs-aims/about">
              About
            </Nav.Link>
          </Nav>
        </Navbar.Collapse>
      </div>
    </Navbar>
  );
}
