import React, {useState} from 'react';
import {Form} from 'react-bootstrap';

import SelectFile from './select-file';
import InfoText from './info-text';
import Notification from './notification';

import './file-upload.scss';
import isValidCoordinates from 'is-valid-coordinates';
import Papa from 'papaparse';

export default function FileUpload(props) {

    const [sites, setSites] = useState([]);
    const [errorMessage, setErrorMessage] =useState("")
    const [importSitesSkipped, setImportSitesSkipped] =useState(0)

    const maxNumberOfSites = 2000;
    const validColNames = {
        lat: ['lat', 'latitude', 'x'],
        lon: ['lon', 'long', 'longitude', 'y'],
        name: ['name', 'site']
    };
    
    let invalidFile = false;
    let newSites = [];
    let newImportSitesSkipped = 0;
    let columnNames = {
        latColumnName: null,
        lonColumnName: null,
        nameColumnName: null
    };
    
    const readFileErrorMessages = {
        "E0": "Error reading file. Please check your CSV file.",
        "E1": "No sites could be imported. Please check your CSV file.",
        "E2": "The CSV file has not the right format!"
    }

    const isValidCsv = result => {
        const latColumnName = result.meta.fields.find(field =>
            validColNames.lat.includes(field.toLowerCase())
        );
        const lonColumnName = result.meta.fields.find(field =>
            validColNames.lon.includes(field.toLowerCase())
        );
        const nameColumnName = result.meta.fields.find(field =>
            validColNames.name.includes(field.toLowerCase())
        );

        if (
            result.meta.fields.length >= 3 &&
            latColumnName &&
            lonColumnName &&
            nameColumnName
        ) {
            columnNames.latColumnName = latColumnName;
            columnNames.lonColumnName = lonColumnName;
            columnNames.nameColumnName = nameColumnName;
            return true;
        }

        return false;
    };

    const addSite = result => {
        const site = result.data;

        if (
            site.hasOwnProperty(columnNames.nameColumnName) &&
            site.hasOwnProperty(columnNames.latColumnName) &&
            site.hasOwnProperty(columnNames.lonColumnName) &&
            isValidCoordinates(
                parseFloat(site[columnNames.lonColumnName]),
                parseFloat(site[columnNames.latColumnName])
            )
        ) {
            newSites.push({
                name: site[columnNames.nameColumnName],
                lat: site[columnNames.latColumnName],
                lon: site[columnNames.lonColumnName]
            });
        } else {
            newImportSitesSkipped++;
        }
    };

    const readFile = (file) => {
        return new Promise((resolve, reject) => {
            Papa.parse(file, {
                complete: () => {
                    // check if at least one site was imported
                    if (newSites.length === 0) {
                        reject('E1');
                    }

                    resolve();
                },
                step: (result, parser) => {
                    // validate CSV file on first row
                    if (newSites.length === 0) {
                        if (!isValidCsv(result)) {
                            parser.abort();
                            reject('E2');
                        }
                    }

                    addSite(result);
                },
                error: () => {
                    reject('E0');
                },
                header: true,
                worker: false,
                skipEmptyLines: true
            });
        });
    }

    const handleChangeStatus = async ({file, meta, remove}, status) => {
        switch (status) {
            case 'done':
                // reset variables
                invalidFile = false;
                newSites = [];
                newImportSitesSkipped = 0;

                await readFile(file)
                    .then(() => {
                        if (newSites.length > maxNumberOfSites) {
                            setImportSitesSkipped(newSites.length - maxNumberOfSites);
                            newSites = newSites.slice(0, maxNumberOfSites);
                            setErrorMessage('Maximum number of sites (' +
                              maxNumberOfSites +
                              ') reached. The remaining site(s) were skipped.');
                        }
                        else {
                            setImportSitesSkipped(newImportSitesSkipped);
                            setErrorMessage("");
                        }
                        setSites(newSites);
                        props.onSitesChange(newSites);
                    })
                    .catch(errorCode => {
                        // Check if error code exists in readFileErrorMessages, otherwise set it to default E0
                        if (!readFileErrorMessages.hasOwnProperty(errorCode)) {
                            errorCode = "E0";
                        }
                        setErrorMessage(readFileErrorMessages[errorCode]);
                        invalidFile = true;
                        remove();
                        setSites([]);
                    });

                break;
            case 'removed':
                newSites = [];
                setSites([]);
                setImportSitesSkipped(0);
                props.onSitesChange(newSites);
                if (invalidFile === false) {
                    setErrorMessage("");
                } else {
                    invalidFile = false;
                }
                break;
            case 'rejected_file_type':
                setErrorMessage('Invalid file type, only CSV files are accepted.');
                invalidFile = true;
                newSites = [];
                setSites([]);
                setImportSitesSkipped(0);
                props.onSitesChange(newSites);
                break;
            default:
                break;
        }
    };


    return (
        <Form.Group className="file-upload">
            <Form.Label column={false}>Import sites from a CSV file:</Form.Label>
            <SelectFile handleChangeStatus={handleChangeStatus}/>
            {sites.length > 0 ? (
                <Notification
                    status="success"
                    message={sites.length + ' rows(s) imported as site(s)'}
                />
            ) : (
                ''
            )}
            {importSitesSkipped > 0 ? (
                <Notification
                    status="warning"
                    message={importSitesSkipped + ' rows(s) skipped.'}
                />
            ) : (
                ''
            )}
            {errorMessage !== "" ? (
                <Notification status="error" message={errorMessage}/>
            ) : (
                ''
            )}

            {sites.length === 0 ? <InfoText/> : ''}
        </Form.Group>
    );
}
