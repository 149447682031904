import React from 'react';
import { Container, Row, Col, Button, Alert } from 'react-bootstrap';
import SitesMap from './sites-map';
import FileUpload from './file-upload';
import SitesTable from 'components/main/extract-data/selected-data-overview/sites-table';

import './step-select-sites.scss';
import LeafletPip from '@mapbox/leaflet-pip';
import Leaflet from 'leaflet';
import * as gbr1_boundaries from '../../../../assets/data/GBR1_boundary';
import * as gbr4_boundaries from '../../../../assets/data/GBR4_boundary';

export default class StepSelectSites extends React.Component {
  constructor(props) {
    super(props);
    LeafletPip.bassackwards = true;

    this.boundaries = {
      gbr1: Leaflet.geoJSON(gbr1_boundaries.default),
      gbr4: Leaflet.geoJSON(gbr4_boundaries.default),
    };
    
    this.state = {
      sitesHaveChanged: false
    };
  }

  shouldComponentUpdate(nextProps, nextState, nextContext) {
    return nextState.sitesHaveChanged;
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    this.setState({sitesHaveChanged: false});
  }

  handleSitesChange = (sites) => {
    this.setState({sitesHaveChanged: true});
    this.props.updateSelectedData('sites', sites);
  };

  getSitesNotificationText() {
    let notAllSitesInGbr1Boundaries = false;
    let notAllSitesInGbr4Boundaries = false;
    for (const site of this.props.selectedData.sites) {
      const position = [site.lat, site.lon];
      const pointInLayerGbr1Result = LeafletPip.pointInLayer(
        position,
        this.boundaries.gbr1,
        true
      );
      if (pointInLayerGbr1Result.length === 0) {
        notAllSitesInGbr1Boundaries = true;
      }

      const pointInLayerGbr4Result = LeafletPip.pointInLayer(
        position,
        this.boundaries.gbr4,
        true
      );
      if (pointInLayerGbr4Result.length === 0) {
        notAllSitesInGbr4Boundaries = true;
      }

      if (notAllSitesInGbr1Boundaries && notAllSitesInGbr4Boundaries) break;
    }

    if (notAllSitesInGbr1Boundaries && notAllSitesInGbr4Boundaries) {
      return 'Sites have been detected outside the boundaries of the GBR1 and GBR4 model. These sites will not return values when being processed.';
    } else if (notAllSitesInGbr1Boundaries && !notAllSitesInGbr4Boundaries) {
      return 'Sites have been detected outside the boundaries of the GBR1 model. When selecting the GBR1 model, these sites will not return values when being processed.';
    } else if (!notAllSitesInGbr1Boundaries && notAllSitesInGbr4Boundaries) {
      return 'Sites have been detected outside the boundaries of the GBR4 model. When selecting the GBR4 model, these sites will not return values when being processed.';
    }

    return null;
  }

  render() {
    const sites = this.props.selectedData.sites;
    const sitesNotificationText = this.getSitesNotificationText();

    return (
      <div className="step-select-sites">
        <h2 className="text-center">Select Sites</h2>
        <p className="sub-header">
          Select the sites for which to extract data. Currently only point-based
          extraction is supported.
        </p>
        {sitesNotificationText && (
          <Container>
            <Alert variant="warning">
              {sitesNotificationText}
              <sup>*</sup>
            </Alert>
            <p className="text-info small">
              <sup>*</sup> The system uses interpolation to match sites to cells
              within the underlying models. Where a site is outside the model
              boundary, or within a masked area of the model (such as the area
              designated as an island), the system will attempt to interpolate
              using surrounding cells. The further outside the boundary, or the
              bigger the mask, the greater the chance that interpolation will
              not be possible, and no data will be returned for that site.
            </p>
          </Container>
        )}
        <Container className="form margin-bottom">
          <Row>
            <Col md={true}>
              <SitesMap
                isActive={this.props.isActive}
                sites={sites}
                updateSiteNotification={this.updateSiteNotification}
                boundaries={{
                  gbr1: gbr1_boundaries.default,
                  gbr4: gbr4_boundaries.default,
                }}
              />
            </Col>
            <Col md={true}>
              <FileUpload
                sites={sites}
                onSitesChange={this.handleSitesChange}
              />
              {sites.length > 0 ? <SitesTable sites={sites} /> : ''}
            </Col>
          </Row>
        </Container>
        <Container>
          <Row>
            <Col className="align-self-end text-end last">
              <Button variant="primary" onClick={this.props.nextStep}>
                Next Step
              </Button>
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
}
