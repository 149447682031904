import React, {useEffect, useRef} from 'react';
import parse from 'html-react-parser';
import Accordion from 'react-bootstrap/Accordion';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faAngleDown} from '@fortawesome/free-solid-svg-icons';
import './faq.scss';

export default function Faq(props) {
  const faqRef = useRef(null);

  useEffect(() => {
    if (props.scrollTo) {
      faqRef.current.scrollIntoView({
        behavior: 'smooth',
        block: 'start'
      });
    }
  });

  return (
    <Accordion.Item
      ref={faqRef}
      eventKey={props.eventKey}
    >
      <Accordion.Header>
        <span>{props.question}</span>
        <FontAwesomeIcon
          className="arrow-icon rotate-icon"
          icon={faAngleDown}
          size="sm"
        />
      </Accordion.Header>
      <Accordion.Body>{parse(props.answer)}</Accordion.Body>
    </Accordion.Item>
  );
}
