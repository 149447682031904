import React from 'react';
import { Route, Switch } from 'react-router-dom';
import './main.scss';
import Home from './home';
import ExtractData from './extract-data';
import Data from './data';
import PrivacyPolicy from './privacy-policy';
import Disclaimer from './disclaimer';
import ContactUs from './contact-us';
import Faqs from './faqs';
import GenericNotFound from './errors/generic-not-found';

export default function Main() {
  return (
    <main>
      <Switch>
        <Route path="/data/:id" component={Data} />
        <Route path="/extract-data" component={ExtractData} />
        <Route path="/faqs" component={Faqs} />
        <Route path="/privacy-policy" component={PrivacyPolicy} />
        <Route path="/disclaimer" component={Disclaimer} />
        <Route path="/contact-us" component={ContactUs} />
        <Route exact path="/" component={Home} />
        <Route component={GenericNotFound} />
      </Switch>
    </main>
  );
}
