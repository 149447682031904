import {Marker, Tooltip, useMap} from "react-leaflet";
import Leaflet from 'leaflet';
import MarkerClusterGroup from 'react-leaflet-cluster';
import './set-marker-cluster.scss';

import iconMarker from 'leaflet/dist/images/marker-icon.png'
import iconRetina from 'leaflet/dist/images/marker-icon-2x.png'
import iconShadow from 'leaflet/dist/images/marker-shadow.png'

const icon = Leaflet.icon({
  iconRetinaUrl: iconRetina,
  iconUrl: iconMarker,
  shadowUrl: iconShadow,
  iconAnchor: [12, 41]
});

export default function SetMarkerCluster(props) {
  const map = useMap();

  const gbrMapBoundaries = [
    {lat: -7, lon: 138},
    {lat: -30, lon: 161}
  ]
  let markers = [];

  if (props.sites.length > 0 && props.isActive) {
    markers = props.sites.map((site, index) => {
      const position = [site.lat, site.lon];
      return (
        <Marker key={'site-' + index} position={position} title={site.name} icon={icon}>
          <Tooltip direction="right" offset={[8, -30]} opacity={1} permanent>
            <span>{site.name}</span>
          </Tooltip>
        </Marker>
      );
    });

    const latLngBounds = new Leaflet.LatLngBounds(
      markers.map(marker => marker.props.position)
    );
    map.fitBounds([
      latLngBounds.getSouthWest(),
      latLngBounds.getNorthEast()
    ], {padding: [50, 50]});
  } 
  else {
    map.fitBounds(gbrMapBoundaries);
  }

  return (
    <>
      {markers.length > 0 ? (
        <MarkerClusterGroup
          disableClusteringAtZoom={props.maxZoom}
          maxClusterRadius={40}
        >
          {markers}
        </MarkerClusterGroup>
      ) : (
        ''
      )}
    </>
  );
}
