import React from 'react';
import { Form } from 'react-bootstrap';
import SitesTable from './sites-table';
import {format} from "date-fns";

export default class SelectedDataOverview extends React.Component {
  render() {
    return (
      <>
        <h3>{this.props.headline}</h3>
        <Form.Group className="form-group">
          <Form.Label column={false}>Sites:</Form.Label>
          <SitesTable sites={this.props.selectedData.sites} />
        </Form.Group>
        <Form.Group className="form-group">
          <Form.Label column={false}>Data collection:</Form.Label>
          <p>
            {this.props.selectedData.dataCollection.name} (Version{' '}
            {this.props.selectedData.dataCollection.version})
          </p>
        </Form.Group>
        <Form.Group className="form-group">
          <Form.Label column={false}>Variables:</Form.Label>
          <p>
            {this.props.selectedData.variables
              .map(variable => variable.name + ' (' + variable.id + ')')
              .join(', ')}
          </p>
        </Form.Group>
        <Form.Group className="form-group">
          <Form.Label column={false}>Date range:</Form.Label>
          <p>
            {format(this.props.selectedData.dateRange.from, 'd LLLL yyyy')} -{' '}
            {format(this.props.selectedData.dateRange.to, 'd LLLL yyyy')}
          </p>
        </Form.Group>
        <Form.Group className="form-group">
          <Form.Label column={false}>Time step:</Form.Label>
          <p>{this.props.selectedData.timeStep}</p>
        </Form.Group>
        <Form.Group className="form-group">
          <Form.Label column={false}>Depths:</Form.Label>
          <p>
            {Object.keys(this.props.selectedData.depths).filter(
              key => this.props.selectedData.depths[key] === true
            ).length > 0
              ? Object.keys(this.props.selectedData.depths)
                  .filter(key => this.props.selectedData.depths[key] === true)
                  .map(depth => depth + ' m')
                  .join(', ')
              : 'No depths'}
          </p>
        </Form.Group>
      </>
    );
  }
}
