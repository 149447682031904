import React from 'react';
import { Container } from 'react-bootstrap';

export default class Disclaimer extends React.Component {
  render() {
    return (
      <Container id="disclaimer">
        <h2>Disclaimer</h2>
        <p>
          You accept all risks and responsibility for losses, damages, costs and
          other consequences resulting directly or indirectly from using this
          site and any information or material available from it.
        </p>
        <p>
          To the maximum permitted by law, AIMS excludes all liability to any
          person arising directly or indirectly from using this site and any
          information or material available from it.
        </p>
      </Container>
    );
  }
}
