import React from 'react';
import './info-text.scss';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';

export default function InfoText() {
  return (
    <div className="info-text">
      <h4>
        <FontAwesomeIcon className="info-icon" icon={faInfoCircle} />
        CSV file format information
      </h4>

      <p>
        The CSV file needs to include the three columns: "name", "lat" and
        "lon". All other columns will be ignored.
      </p>
      <label>Example file:</label>
      <pre>
        <code>
          name,lat,lon
          <br />
          site 1,-18.15629,147.485962
          <br />
          site 2,-19.16884,146.89124
        </code>
      </pre>
    </div>
  );
}
