import React from 'react';

import './stepper.scss';

export default class Stepper extends React.Component {
  render() {
    return (
      <div className="stepper">
        <div className="steps">
          {this.props.steps.map((step, index) => {
            let classNames = 'stepper-step ';
            if (index + 1 === this.props.activeStep) {
              if (this.props.isFailed) {
                classNames += 'is-error';
              } else {
                classNames += 'is-active';
              }
            } else if (index + 1 < this.props.activeStep) {
              classNames += 'is-complete';
            }

            return (
              <div key={'step-' + index} className={classNames}>
                <div className="stepper-indicator">
                  <span className="stepper-info">{step.indicator}</span>
                </div>
                <div className="stepper-label">{step.title}</div>
              </div>
            );
          })}
        </div>
        <div className="step-panel">
          <p>{this.props.steps[this.props.activeStep - 1].description}</p>
        </div>
      </div>
    );
  }
}
