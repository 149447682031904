import React from 'react';

import Dropzone from 'react-dropzone-uploader';

import './select-file.scss';
import { faFolderOpen } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const MSG_DEFAULT = (
  <React.Fragment key="select-file-msg-default">
    <strong>Drag and drop a CSV file</strong>
    or <br />
    <span className="btn btn-primary">
      <FontAwesomeIcon className="folder-icon" icon={faFolderOpen} />
      Choose a CSV file
    </span>
  </React.Fragment>
);

const ACCEPTED_FILES = '.csv';

export default function SelectFile(props) {
  // override Input to move input out of label tag (bug in MS Edge)
  const Input = ({
    className,
    labelClassName,
    labelWithFilesClassName,
    style,
    labelStyle,
    labelWithFilesStyle,
    getFilesFromEvent,
    accept,
    multiple,
    disabled,
    content,
    withFilesContent,
    onFiles,
    files
  }) => {
    return (
      <>
        <label
          htmlFor="select-file-dropzone-input"
          className={
            files.length > 0 ? labelWithFilesClassName : labelClassName
          }
          style={files.length > 0 ? labelWithFilesStyle : labelStyle}
        >
          {files.length > 0 ? withFilesContent : content}
        </label>
        <input
          id="select-file-dropzone-input"
          className={className}
          style={style}
          type="file"
          accept={accept}
          multiple={multiple}
          disabled={disabled}
          onChange={async e => {
            const target = e.target;
            const chosenFiles = await getFilesFromEvent(e);
            onFiles(chosenFiles);
            //@ts-ignore
            target.value = null;
          }}
        />
      </>
    );
  };

  return (
    <Dropzone
      InputComponent={Input}
      onChangeStatus={props.handleChangeStatus}
      accept={ACCEPTED_FILES}
      maxFiles={1}
      multiple={false}
      autoUpload={false}
      inputContent={(files, extra) =>
        extra.reject ? 'CSV files only' : MSG_DEFAULT
      }
      addClassNames={{
        dropzone: files =>
          'select-file-dropzone' + (files.length > 0 ? ' accepted' : ''),
        dropzoneActive: 'active',
        dropzoneReject: 'reject',
        preview: 'select-file-preview'
      }}
      classNames={{ inputLabel: 'select-file-label' }}
      submitButtonDisabled={true}
    />
  );
}
