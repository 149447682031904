import React from 'react';
import { Form } from 'react-bootstrap';

export default class DownloadLinks extends React.Component {
  render() {
    let downloadLinkList;
    if (
      Array.isArray(this.props.downloadUrls) &&
      this.props.downloadUrls.length > 0
    ) {
      downloadLinkList = (
        <ul>
          {this.props.downloadUrls.map((url, index) => (
            <li key={'downloadLink-' + index}>
              <a href={url}>{url.split('/').pop()}</a>
              <br />
              Licence:{' '}
              <div
                className="licence"
                dangerouslySetInnerHTML={{
                  __html: this.props.dataCollection.licence
                }}
              />
            </li>
          ))}
        </ul>
      );
    } else {
      downloadLinkList = <p>No files for download.</p>;
    }

    return (
      <>
        <h3>Download</h3>
        <Form.Group>
          <Form.Label column={false}>Files:</Form.Label>
          {downloadLinkList}
        </Form.Group>
      </>
    );
  }
}
