import React from 'react';
import { Form } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';

export default class TimeStep extends React.Component {
  handleTimeStepChange = event => {
    this.props.onTimeStepChange(event.target.value);
  };

  render() {
    const { selectedTimeStep } = this.props;

    return (
      <div className="time-step">
        <Form.Group>
          <Form.Label column={false}>Select time step:</Form.Label>
          {this.props.dataCollectionTimeSteps.map((timeStep, index) => (
            <Form.Check
              key={'time-step-' + index}
              type="radio"
              id={'time-step-' + timeStep}
              value={timeStep}
              name="time-step"
              label={timeStep}
              onChange={this.handleTimeStepChange}
              checked={selectedTimeStep === timeStep}
            />
          ))}
          {this.props.dataCollectionTimeStepsInfo === '' ? (
            ''
          ) : (
            <p className="text-info small">
              <FontAwesomeIcon className="info-icon" icon={faInfoCircle} />
              {this.props.dataCollectionTimeStepsInfo}
            </p>
          )}
        </Form.Group>
      </div>
    );
  }
}
