import React from 'react';
import './sites-table.scss';
import { Table } from 'react-bootstrap';

export default class SitesTable extends React.Component {
  render() {
    const siteRows = this.props.sites.map((site, index) => {
      return (
        <tr key={'site-' + index}>
          <td>{site.name}</td>
          <td>{site.lat}</td>
          <td>{site.lon}</td>
        </tr>
      );
    });

    return (
      <Table
        className="sites-table"
        striped
        bordered
        hover
        size="sm"
        responsive
      >
        <thead>
          <tr>
            <th>Site</th>
            <th>Latitude</th>
            <th>Longitude</th>
          </tr>
        </thead>
        <tbody>{siteRows}</tbody>
      </Table>
    );
  }
}
