import React from 'react';
import './app-footer.scss';
import Nav from 'react-bootstrap/Nav';

export default function AppFooter() {
  return (
    <footer className="app-footer">
      <div className="container">
        <Nav as="ul">
          <Nav.Item as="li">
            <Nav.Link href="/disclaimer">Disclaimer</Nav.Link>
          </Nav.Item>
          <Nav.Item as="li">
            <Nav.Link href="/privacy-policy">Privacy Policy</Nav.Link>
          </Nav.Item>
          <Nav.Item as="li">
            <Nav.Link href="/contact-us">Contact Us</Nav.Link>
          </Nav.Item>
        </Nav>
      </div>
    </footer>
  );
}
