import React from 'react';
import { Container } from 'react-bootstrap';

export default class ContactUs extends React.Component {
  render() {
    return (
      <Container id="contact-us">
        <h2>Contact Us</h2>
        <p>
          For further information please contact us at{' '}
          <a href={'mailto:' + process.env.REACT_APP_CONTACT_EMAIL_ADDRESS}>
            {process.env.REACT_APP_CONTACT_EMAIL_ADDRESS}
          </a>
          .
        </p>
      </Container>
    );
  }
}
