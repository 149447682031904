import {useEffect, useState} from 'react';
import {useLocation} from 'react-router-dom';
import ReactGA from 'react-ga4';

const usePageTracking = () => {
  const location = useLocation();
  const [initialized, setInitialized] = useState(false);

  useEffect(() => {
    ReactGA.initialize(process.env.REACT_APP_GA_ID, {
      debug: process.env.REACT_APP_GA_DEBUG === 'true',
    });
    setInitialized(true);
  }, []);
  
  useEffect(() => {
    if (initialized) {
      ReactGA.send({hitType: "pageview", page: location.pathname + location.hash});
    }
  }, [initialized, location]);
};

export default usePageTracking;
