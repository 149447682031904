import React from 'react';
import { Container } from 'react-bootstrap';

export default class PrivacyPolicy extends React.Component {
  render() {
    return (
      <Container id="privacy-policy">
        <h2>Privacy Policy</h2>
        <p>
          The eReefs data extraction tool is an online service managed by the
          Australian Institute of Marine Science. We respect and protect the
          privacy of our users. This privacy policy tells you how we collect and
          use information.
        </p>
        <h3>Information we may request</h3>
        <p>
          To use the eReefs data extraction tool we ask you for an email address
          and optional information about the intended purpose of the data
          extraction. The email address is used to notify you of the progress of
          the requested data extraction and may also be used to contact you if
          there is an issue with the data extraction.
        </p>
        <p>
          From the information you provide we will produce non-personally
          identified summaries on the use of the tool for reporting purposes and
          to further improve the service.
        </p>
        <p>
          We will not add you to a mailing list, or give your personal
          information to third parties without your consent, unless required by
          law.
        </p>

        <h3>Content created using this service</h3>
        <p>
          Each data extraction request creates a new public web page that
          provides access to the extracted data along with details of the
          extraction. A link to this page is provided by email when you submit a
          data extraction. Your email address will not be displayed on this
          page.
        </p>

        <h3>How we protect your Personal Information</h3>
        <p>
          This online service is hosted in Australia in secure, government
          accredited facilities on Amazon Web Services. The email address you
          provide is encrypted. To help protect the privacy of data and personal
          information we collect and hold, we maintain physical, technical and
          administrative safeguards. We update and test our security technology
          on an ongoing basis.
        </p>

        <h3>Website analytics</h3>
        <p>
          To improve your experience on our site, we use "cookies". Cookies are
          an industry standard and most major websites use them. A cookie is a
          small text file that our site may place on your computer as a tool to
          remember your preferences. You may refuse the use of cookies by
          selecting the appropriate settings on your browser, however please
          note that if you do this you may not be able to use the full
          functionality of this website.
        </p>
        <p>
          This website uses Google Analytics. These services transmit website
          traffic data to Google servers, that may be located outside of
          Australia. We use reports provided by these analytics tools to help us
          understand website traffic and usage. By using this website, you
          consent to the processing of data about you in the manner described in{' '}
          <a
            href="https://policies.google.com/privacy"
            target="_blank"
            rel="noopener noreferrer"
            title="Google's Privacy Policy"
          >
            Google's Privacy Policy
          </a>
          , for the purposes set out above. You can opt out of Google Analytics
          if you disable or refuse the cookie, disable JavaScript, or use the{' '}
          <a
            href="https://tools.google.com/dlpage/gaoptout"
            target="_blank"
            rel="noopener noreferrer"
            title="Google Analytics opt-out"
          >
            opt-out service provided by Google
          </a>
          .
        </p>
      </Container>
    );
  }
}
